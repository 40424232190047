import {NavLink} from 'react-router-dom';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const HomeSlider=(props)=>{
    return(
        <>
		 <section className="upcoming-meetings" id="latest">
		  <div className="container">
			<div className="row">
      <div className="col-lg-12">
            <div className="section-heading">
                <h2>{props && props.name ? props.name : ''}</h2>
            </div>
      </div>
			  <div className="col-lg-12">
				<Swiper
					spaceBetween={30}
					centeredSlides={true}
					autoplay={{
					delay: 4500,
					disableOnInteraction: false,
					}}
					pagination={{
					clickable: true,
					}}
					navigation={false}
					modules={[Autoplay, Pagination, Navigation]}
					className="align-items-center"
				>
		        	{props && props.data && props.data.length > 0 && props.data.slice(0, 6).map((top, tw)=>{
                    return(   
						<SwiperSlide key={tw}>
							<div className="swiper-slide" key={tw}>
								<div className="bus-feed-item-primary">
									<div className="biz-feed-item-primary-element">
										<img alt="" src={top.image} className="lazy-img-bg"/>
										<div className="biz-feed-item-element-meta">
											<div className="biz-primary-title"><NavLink to={top.slug}>{top.title}</NavLink></div>
											<div className="post-meta">
											<span>{top.tags && top.tags.length > 0 ? top.tags[0].name :''}</span>
					<span>{top.dateTime}</span>
											</div>
											<p>{top.content}</p>
					<div class="main-button-red"><NavLink to={top.slug}><div class="mvp-vid-box-wrap mvp-vid-marg zoom"><i class="fa fa-camera" aria-hidden="true"></i></div><div class="scroll-to-section"><span>View Event</span></div></NavLink></div>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						)})}
      			</Swiper>
			  </div>
			</div>
		  </div>
		</section>   
    
        </>
    )
}
export default HomeSlider;